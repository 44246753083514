import { connect } from "react-redux";
import MemberContainer from "./MemberContainer";

// 팀 정보 가져오기
const mapStateToProps = state => {
  const { company } = state;

  return {
    member: company.member
  };
};

// 소설벼튼 클릭 이벤트 디스패치
const mapDispatchToProps = () => {
  return {
    // 디스패치로 넘기지 않고 새로운 창 오픈
    onClickSocialButton: target => {
      window.open(target);
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberContainer);
