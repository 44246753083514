import React from "react";

const HeaderPresenter = () => {
  return (
    <header className="masthead text-center text-white d-flex">
      <div style={{ minHeight: 100 }} />
    </header>
  );
};

export default HeaderPresenter;
