import { connect } from "react-redux";
import PortfolioContainer from "./PortfolioContainer";

import { actionCreators as portfolioActions } from "../../redux/modules/portfolio";

const mapStateToProps = state => {
  return {
    selectCategory: state.portfolio.selectCategory
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClickCategory: selectCategory =>
      dispatch(portfolioActions.onClickCategory(selectCategory))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioContainer);
