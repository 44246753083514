const BaseApiUrl = "//api.goodboypic.com";
// const LocalApiURL = "http://localhost:8001"

class APIManager {
  // 리스트 가져오기(api 작동 테스트용)
  getInquiryList = async () => {
    const url = `${BaseApiUrl}/inquiry/`;
    return await fetch(url).then((res) => res.json());
  };

  // 문의내용 전송
  postInquiry = async (jsonData) => {
    const url = `${BaseApiUrl}/inquiry/`;

    return await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonData),
    });
  };

  // 타이틀 문구 가져오기
  getMainTitle = async () => {
    const url = `${BaseApiUrl}/main/title/`;

    return await fetch(url).then((res) => res.json());
  };

  // 회사 정보 가져오기
  getCompanyInfo = async () => {
    const url = `${BaseApiUrl}/main/info/`;

    return await fetch(url).then((res) => res.json());
  };

  // 포트폴리오 내용 가져오기
  getPortfolio = async () => {
    const url = `${BaseApiUrl}/portfolio/`;

    return await fetch(url).then((res) => res.json());
  };
}

export default APIManager;
