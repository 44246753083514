import React from "react";

const InputFormPresenter = props => {
  const { placeholder, type, id, value, onChange, errorMessage } = props;
  return (
    <div className="form-group">
      <input
        className="form-control"
        id={id}
        type={type}
        placeholder={placeholder}
        required="required"
        value={value}
        onChange={e => onChange(e.target.value)}
      />
      <p className="help-block text-danger">{errorMessage}</p>
    </div>
  );
};

export default InputFormPresenter;
