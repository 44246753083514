import React, { PureComponent } from "react";
import ContactPresenter from "./ContactPresenter";
import APIManager from "../../utils/api";
const naver = window.naver;

class ContactContainer extends PureComponent {
  constructor(props) {
    super(props);

    this._apiManager = new APIManager();

    this.state = {
      inquiryError: {},
      phoneNumber: "",
      email: ""
    };
  }
  handleSubmit = async event => {
    // 이벤트 초기화
    event.preventDefault();

    const {
      inputUserName: username,
      inputEmail: email,
      inputPhoneNumber: phone_number,
      inputBudget: budget_required,
      inputContent: content,
      onChangeUserName,
      onChangeEmail,
      onChangePhoneNumber,
      onChangeBudget,
      onChangeContent
    } = this.props;

    // 데이터 전송
    const createData = {
      username,
      email,
      phone_number,
      budget_required,
      content
    };

    try {
      const createResultData = await this._apiManager.postInquiry(createData);

      if (createResultData.ok) {
        onChangeUserName("");
        onChangeEmail("");
        onChangePhoneNumber("");
        onChangeBudget("");
        onChangeContent("");
        window.alert("신청 되었습니다.");
        this.setInquiryError({});
      } else {
        createResultData.json().then(res => {
          this.setInquiryError(res);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  setInquiryError = inquiryError => {
    this.setState({
      inquiryError: { ...inquiryError }
    });
  };

  async componentDidMount() {
    // 회사정보 갱신
    const companyInfo = await this._apiManager.getCompanyInfo();
    const { phone_number: phoneNumber, email, address, geolocation } = companyInfo;
    this.setState({
      phoneNumber,
      email
    });

    const splitedGeolocation = geolocation.split(",");
    const lat = splitedGeolocation[0];
    const lng = splitedGeolocation[1];

    // 지도 옵션
    const mapOptions = {
      center: new naver.maps.LatLng(lat, lng),
      zoom: 17
    };

    // 지도 생성
    const map = new naver.maps.Map("map", mapOptions);

    // 지도 마크 추가
    const marker = new naver.maps.Marker({
      position: new naver.maps.LatLng(lat, lng),
      map: map
    });

    // 지도 정보창 띄우기
    const contentString = [
      '<div class="iw_inner">',
      '   <div style="padding: 0 10px; font-size: 12px; text-align: center; line-height: 44px;">' +
        address +
        "</div>",
      "</div>"
    ].join("");

    const infowindow = new naver.maps.InfoWindow({
      content: contentString
    });

    infowindow.open(map, marker);
  }

  render() {
    return (
      <ContactPresenter
        {...this.props}
        {...this.state}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

export default ContactContainer;
