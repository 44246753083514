import React from "react";
import { InputForm } from "./forms";
import "../../css/contact.css";

const ContactPresenter = props => {
  const {
    // props
    handleSubmit,
    phoneNumber,
    email,

    inputUserName,
    inputEmail,
    inputPhoneNumber,
    inputBudget,
    inputContent,

    inquiryError: {
      username: usernameError,
      email: emailError,
      phone_number: phoneNumberError,
      budget_required: budgetRequiredError,
      content: contentError
    },

    // func
    onChangeUserName,
    onChangeEmail,
    onChangePhoneNumber,
    onChangeBudget,
    onChangeContent
  } = props;
  return (
    <section id="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Contact Us</h2>
            <h3 className="section-subheading text-muted">
              문의사항을 남겨주시면 당담자가 확인후 빠르시간 내에
              연락드리겠습니다.
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <form id="contactForm" name="sentMessage">
              <div className="row">
                <div className="col-md-6">
                  <InputForm
                    type="text"
                    id="name"
                    placeholder="담당자명"
                    errorMessage={usernameError}
                    value={inputUserName}
                    onChange={onChangeUserName}
                  />
                  <InputForm
                    type="email"
                    id="email"
                    placeholder="이메일"
                    errorMessage={emailError}
                    value={inputEmail}
                    onChange={onChangeEmail}
                  />
                  <InputForm
                    type="tel"
                    id="phone"
                    placeholder="연락처"
                    errorMessage={phoneNumberError}
                    value={inputPhoneNumber}
                    onChange={onChangePhoneNumber}
                  />
                  <InputForm
                    type="text"
                    id="budget"
                    placeholder="소요예산"
                    errorMessage={budgetRequiredError}
                    value={inputBudget}
                    onChange={onChangeBudget}
                  />
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      id="message"
                      placeholder="상세내용(프로젝트에 관한 문의하실 내용을 작성해 주세요)"
                      required="required"
                      data-validation-required-message="Please enter a message."
                      value={inputContent}
                      onChange={e => onChangeContent(e.target.value)}
                    />
                    <p className="help-block text-danger">{contentError}</p>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-lg-12 text-center">
                  <div id="success" />
                  <button
                    id="sendMessageButton"
                    className="btn btn-primary btn-xl text-uppercase"
                    onClick={handleSubmit}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="row"
        style={{ marginTop: 50, color: "#FFF", marginRight: 0, marginLeft: 0 }}
      >
        <div className="col-lg-4 ml-auto text-center">
          <i className="fas fa-mobile-alt fa-3x mb-3 sr-contact-1" />
          <p>{phoneNumber}</p>
        </div>
        <div className="col-lg-4 mr-auto text-center">
          <i className="fas fa-envelope fa-3x mb-3 sr-contact-2" />
          <p>
            <a href={`mailto:${email}`}>{email}</a>
          </p>
        </div>
      </div>

      <div className="naver-cloud-map" id="map" />
    </section>
  );
};

export default ContactPresenter;
