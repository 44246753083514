import React, { PureComponent } from "react";
import AboutPresenter from "./AboutPresenter";
import APIManager from "../../utils/api";

class AboutConatiner extends PureComponent {
  constructor(props) {
    super(props);
    this._apiManager = new APIManager();

    this.state = {
      mainTitle: ""
    };
  }

  componentDidMount() {
    // 메인타이틀 갱신
    const mainTitle = this._apiManager.getMainTitle();
    mainTitle.then(res => {
      this.setState({
        mainTitle: res.title
      });
    });
  }

  render() {
    return <AboutPresenter {...this.state} />;
  }
}

export default AboutConatiner;
