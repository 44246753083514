import React, { PureComponent } from "react";
import PortfolioPresenter from "./PortfolioPresenter";
import APIManager from "../../utils/api";

class PortfolioContainer extends PureComponent {
  constructor(props) {
    super(props);
    this._apiManager = new APIManager();

    this.state = {
      portfolioList: []
    };
  }

  async componentDidMount() {
    const portfolioList = await this._apiManager.getPortfolio();
    this.setState({
      portfolioList
    });
  }

  render() {
    const { selectCategory } = this.props;
    let { portfolioList } = this.state;

    if (selectCategory) {
      portfolioList = portfolioList.filter(
        item => item.category === selectCategory
      );
    }

    return <PortfolioPresenter {...this.props} portfolioList={portfolioList} />;
  }
}

export default PortfolioContainer;
