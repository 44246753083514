import React from "react";
import NavPresenter from "./NavPresenter";
const { detect } = require("detect-browser");
const browser = detect();

let navClassName = "";
switch (browser.name) {
  case "ie":
    navClassName =
      "navbar navbar-expand-lg navbar-light fixed-top navbar-shrink";
    break;

  default:
    navClassName = "navbar navbar-expand-lg navbar-light fixed-top";
    break;
}

const NavContainer = () => {
  return <NavPresenter navClassName={navClassName} />;
};

export default NavContainer;
