import React, { Component } from "react";
import Headers from "../../components/Headers";
import About from "../../components/About";
import Services from "../../components/Services";
import Portfolio from "../../components/Portfolio";
import Team from "../../components/Team";
import Contact from "../../components/Contact";
import { Setup } from "../../utils/setup.js";

import "../../css/base.css";
import "../../css/bootstrap.css";
import "../../css/all.css";
import "../../css/magnific-popup.css";
import "../../css/creative.css";

/**
 * MainPresenter
 */
class MainPresenter extends Component {
  componentDidMount() {
    Setup();
  }

  render() {
    return (
      <div>
        <Headers />
        <About />
        <Services />
        <Portfolio />
        <Team />
        <Contact />
      </div>
    );
  }
}

export default MainPresenter;
