import React from "react";
import Member from "./Member";

const TeamPresenter = () => {
  return (
    <section id="team">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading">Team</h2>
            <hr className="my-4" />
          </div>
        </div>

        <div className="row">
          <Member memberNumber={0} />
        </div>
      </div>
    </section>
  );
};

export default TeamPresenter;
