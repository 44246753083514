import React from "react";

const PortfolioItemPresenter = ({ info }) => {
  const portfolioImage = info.thumbnail_url
    ? info.thumbnail_url
    : "//static.goodboypic.com/images/base.jpg";

  const link = info.url ? info.url : "#";

  return (
    <div className="col-md-4 col-sm-6 portfolio-item">
      <a
        className="portfolio-link"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="portfolio-hover">
          <div className="portfolio-hover-content">
            <i className="fas fa-plus fa-3x" />
          </div>
        </div>

        <img
          className="img-fluid"
          src={portfolioImage}
          style={{ height: 200, width: "100%" }}
          alt=""
        />
      </a>

      <div className="portfolio-caption">
        <h4>{info.title}</h4>
        <p className="text-muted">{info.description}</p>
      </div>
    </div>
  );
};

export default PortfolioItemPresenter;
