import React from "react";
import MemberPresenter from "./MemberPresneter";

const MemberContainer = props => {
  const { member, memberNumber, onClickSocialButton } = props;
  const memberInfo = member[memberNumber];

  return (
    <MemberPresenter
      imageFile={memberInfo.profileImage}
      memberName={memberInfo.name}
      memberPosition={memberInfo.position}
      facebook={memberInfo.facebook}
      instagram={memberInfo.instagram}
      onClickSocialButton={onClickSocialButton}
    />
  );
};

export default MemberContainer;
