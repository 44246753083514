// 소셜 버튼 클릭
const ON_CLICK_SOCIAL_BUTTON = "ON_CLICK_SOCIAL_BUTTON";

// initial state
const initialState = {
  member: [
    {
      profileImage: 1,
      name: "김결",
      position: "",
      facebook: "https://www.facebook.com/",
      instagram: "https://www.instagram.com/goodboypic/"
    }
  ]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_CLICK_SOCIAL_BUTTON:
      return applyOnClickSocialButton(state, action);

    default:
      return state;
  }
};

// 소설버튼 클릭 이벤트
const onClickSocialButton = target => {
  return {
    type: ON_CLICK_SOCIAL_BUTTON,
    target
  };
};

const applyOnClickSocialButton = (state, action) => {
  return state;
};

// Export Actions
export const actionCreators = {
  onClickSocialButton
};

export default reducer;
