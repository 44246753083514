import React, { Fragment } from "react";
import Nav from "./Nav";
import Header from "./Header";

const HeadersPresenter = () => {
  return (
    <Fragment>
      <Nav />
      <Header />
    </Fragment>
  );
};

export default HeadersPresenter;
