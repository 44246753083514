import { connect } from "react-redux";
import ContactContainer from "./ContactContainer";
import { actionCreators as contactActions } from "../../redux/modules/contact";

// 대표전화, 이메일 정보 가져오기
const mapStateToProps = state => {
  const {
    // company,
    contact: {
      username: inputUserName,
      email: inputEmail,
      phoneNumber: inputPhoneNumber,
      budget: inputBudget,
      content: inputContent
    }
  } = state;

  return {
    // phoneNumber: company.phoneNumber,
    // email: company.email,
    inputUserName,
    inputEmail,
    inputPhoneNumber,
    inputBudget,
    inputContent
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // 담당자명 입력 변경 함수
    onChangeUserName: username =>
      dispatch(contactActions.onChangeUserName(username)),

    // 이메일 입력 변경 함수
    onChangeEmail: email => dispatch(contactActions.onChangeEmail(email)),

    // 연락처 입력 변경 함수
    onChangePhoneNumber: phoneNumber =>
      dispatch(contactActions.onChangePhoneNumber(phoneNumber)),

    // 소요예산 입력 변경 함수
    onChangeBudget: budget => dispatch(contactActions.onChangeBudget(budget)),

    // 상세내용 입력 변경 함수
    onChangeContent: content =>
      dispatch(contactActions.onChangeContent(content))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactContainer);
