import React from "react";

const ServicesPresenter = () => {
  return (
    <section id="services">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading">Service</h2>
            <hr className="my-4" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 text-center">
            <div className="service-box mt-5 mx-auto">
              <i className="fas fa-4x fa-user-check text-primary mb-3 sr-icon-1" />
              <h3 className="mb-3">창의적 기획</h3>
              {/* <p className="text-muted mb-0">창의적 기획</p> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-6 text-center">
            <div className="service-box mt-5 mx-auto">
              <i className="fas fa-4x fa-pen-fancy text-primary mb-3 sr-icon-2" />
              <h3 className="mb-3">세밀한 디자인</h3>
              {/* <p className="text-muted mb-0">세밀한 디자인</p> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-6 text-center">
            <div className="service-box mt-5 mx-auto">
              <i className="fas fa-4x fa-video text-primary mb-3 sr-icon-3" />
              <h3 className="mb-3">감각적 연출</h3>
              {/* <p className="text-muted mb-0">감각적 연출</p> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-6 text-center">
            <div className="service-box mt-5 mx-auto">
              <i className="fas fa-4x fa-film text-primary mb-3 sr-icon-4" />
              <h3 className="mb-3">트렌디한 편집</h3>
              {/* <p className="text-muted mb-0">트렌디한 편집</p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesPresenter;
