import React from "react";

const MemberPresenter = ({
  imageFile,
  memberName,
  memberPosition,
  instagram,
  onClickSocialButton
}) => {
  const image = require(`../../../img/team/${imageFile}.png`);

  return (
    <div className="col-sm">
      <div className="team-member">
        <img className="mx-auto rounded-circle" src={image} alt="" />
        <h4>{memberName}</h4>
        <p className="text-muted">{memberPosition}</p>
        <ul className="list-inline social-buttons">
          {/* <li className="list-inline-item">
            <div onClick={() => onClickSocialButton(facebook)}>
              <i className="fab fa-facebook-f" />
            </div>
          </li> */}
          <li className="list-inline-item">
            <div onClick={() => onClickSocialButton(instagram)}>
              <i className="fab fa-linkedin-in" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MemberPresenter;
