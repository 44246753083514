// 포트폴리오 카테고리 버튼 클릭
const ON_CLICK_CATEGORY = "ON_CLICK_CATEGORY";

// initialState
const initialState = {
  selectCategory: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_CLICK_CATEGORY:
      return applyOnClickCategory(state, action);

    default:
      return state;
  }
};

// region ON_CLICK_CATEGORY
const onClickCategory = categoryType => {
  return {
    type: "ON_CLICK_CATEGORY",
    categoryType
  };
};

const applyOnClickCategory = (state, action) => {
  const { categoryType: selectCategory } = action;
  return {
    ...state,
    selectCategory
  };
};

export const actionCreators = {
  onClickCategory
};

export default reducer;
