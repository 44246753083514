import React from "react";

const AboutPresenter = props => {
  const { mainTitle } = props;

  return (
    <section className="bg-primary" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <h2 className="section-heading text-white">
              {mainTitle.split("\n").map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </h2>
            <hr className="light my-4" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutPresenter;
