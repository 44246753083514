import React from "react";
import PortfolioItem from "./Item";

const PortfolioPresenter = ({ portfolioList, onClickCategory }) => {
  return (
    <section className="bg-light" id="portfolio">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading">Portfolio</h2>
            <hr className="my-4" />
          </div>
        </div>
        <div className="row" style={{ marginBottom: "2rem" }}>
          <button
            type="button"
            className="btn left btn-secondary"
            onClick={() => onClickCategory(null)}
          >
            전체
          </button>
          <button
            type="button"
            className="btn left btn-secondary"
            onClick={() => onClickCategory("sketch")}
          >
            스케치
          </button>
          <button
            type="button"
            className="btn left btn-secondary"
            onClick={() => onClickCategory("brand")}
          >
            브랜드필름
          </button>
          <button
            type="button"
            className="btn left btn-secondary"
            onClick={() => onClickCategory("fashion")}
          >
            패션필름
          </button>
          <button
            type="button"
            className="btn left btn-secondary"
            onClick={() => onClickCategory("trailer")}
          >
            트레일러
          </button>
          <button
            type="button"
            className="btn left btn-secondary"
            onClick={() => onClickCategory("marketing")}
          >
            홍보영상
          </button>
        </div>

        <div className="row" style={{ minHeight: 733 }}>
          {portfolioList.map((info, key) => {
            return <PortfolioItem info={info} key={key} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default PortfolioPresenter;
