// 담당자명 변경 액션
const ON_CHANGE_USER_NAME = "ON_CHANGE_USER_NAME";

// 이메일 변경 액션
const ON_CHANGE_EMAIL = "ON_CHANGE_EMAIL";

// 연락처 변경 액션
const ON_CHANGE_PHONE_NUMBER = "ON_CHANGE_PHONE_NUMBER";

// 소요예산 변경 액션
const ON_CHANGE_BUDGET = "ON_CHANGE_BUDGET";

// 상세내용 변경 액션
const ON_CHANGE_CONTENT = "ON_CHANGE_CONTENT";

const inisialState = {
  username: "",
  email: "",
  phoneNumber: "",
  budget: "",
  content: "",
};

const reducer = (state = inisialState, action) => {
  switch (action.type) {
    case ON_CHANGE_USER_NAME:
      return applyOnChangeUserName(state, action);

    case ON_CHANGE_EMAIL:
      return applyOnChangeEmail(state, action);

    case ON_CHANGE_PHONE_NUMBER:
      return applyOnChangePhoneNumber(state, action);

    case ON_CHANGE_BUDGET:
      return applyOnChangeBudget(state, action);

    case ON_CHANGE_CONTENT:
      return applyOnChangeContent(state, action);

    default:
      return state;
  }
};

// region ON_CHANGE_USER_NAME
const onChangeUserName = username => {
  return {
    type: ON_CHANGE_USER_NAME,
    username
  };
};

const applyOnChangeUserName = (state, action) => {
  const { username } = action;

  return {
    ...state,
    username
  };
};
// endregion ON_CHANGE_USER_NAME

// region ON_CHANGE_EMAIL
const onChangeEmail = email => {
  return {
    type: ON_CHANGE_EMAIL,
    email
  };
};

const applyOnChangeEmail = (state, action) => {
  const { email } = action;

  return {
    ...state,
    email
  };
};
// endregion ON_CHANGE_EMAIL

// region ON_CHANGE_PHONE_NUMBER
const onChangePhoneNumber = phoneNumber => {
  return {
    type: ON_CHANGE_PHONE_NUMBER,
    phoneNumber
  };
};

const applyOnChangePhoneNumber = (state, action) => {
  const { phoneNumber } = action;

  // 연락처 문자열 필터링 기능 추가
  // ...

  return {
    ...state,
    phoneNumber
  };
};
// endregion ON_CHANGE_PHONE_NUMBER

// region ON_CHANGE_BUDGET
const onChangeBudget = budget => {
  return {
    type: ON_CHANGE_BUDGET,
    budget
  };
};

const applyOnChangeBudget = (state, action) => {
  const { budget } = action;

  return {
    ...state,
    budget
  };
};
// endregion ON_CHANGE_BUDGET

// region ON_CHANGE_CONTENT
const onChangeContent = content => {
  return {
    type: ON_CHANGE_CONTENT,
    content
  };
};

const applyOnChangeContent = (state, action) => {
  const { content } = action;

  return {
    ...state,
    content
  };
};
// endregion ON_CHANGE_CONTENT

export const actionCreators = {
  onChangeUserName,
  onChangeEmail,
  onChangePhoneNumber,
  onChangeBudget,
  onChangeContent
};

export default reducer;
